/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import LayoutMobile from "../../components/LayoutMobile";
import BottomNav from "../../components/BottomNav";
import { getAccountInfo } from "../../services/api";

export default function NewProfile() {
  const navigate = useNavigate();
  // const [token, setToken] = useState<string | null | undefined>();
  const [userData, setUserData] = useState<any>();
  const [walletData, setWalletData] = useState<any>();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAccountInfo();
      console.log(res);
      setUserData(res?.user);
      setWalletData(JSON.parse(res?.wallet));
    };
    fetchData();
  }, []);

  return (
    <>
      <LayoutMobile>
        <PageHeader title="My Information" />
        {/* user data  */}
        <div className="shadow p-3 m-3 w-80 rounded-3">
          <h4 className="">User Data</h4>
          {userData && (
            <div className="p-2">
              <p>Username: {userData.username}</p>
              <p>ID: {userData.id}</p>
              <p>Language: {userData.lang_tag}</p>
              <p>Metadata: {userData.metadata}</p>
              <p>Created Time: {userData.create_time}</p>
              <p>Updated Time: {userData.update_time}</p>
            </div>
          )}
        </div>
        {/* wallet data  */}
        <div className="shadow p-3 m-3 w-80 rounded-3">
          <h4>Wallet Data</h4>
          {walletData && (
            <div className="p-2">
              <p>CAD: {walletData.CAD}</p>
              <p>USDT: {walletData.USDT}</p>
              <p>Diamond: {walletData.diamond}</p>
              <p>Gold: {walletData.gold}</p>
            </div>
          )}
        </div>

        <div className="text-center my-4">
          <Button
            onClick={() => navigate("/charge")}
            className="m-3 bg-primary"
            variant="contained"
            size="large"
          >
            充值 CAD
          </Button>
          <Button
            onClick={handleLogout}
            className="m-3 bg-light text-dark"
            variant="contained"
            size="large"
          >
            登出
          </Button>
        </div>
      </LayoutMobile>
      <BottomNav />
    </>
  );
}
