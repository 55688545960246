import axios from "axios";

// import request from "../utils/request";

const getConfig = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return config;
};

const getAccountInfo = async () => {
  try {
    const url = "https://wallet.a99h.com:444/v2/account";
    const res = await axios.get(url, getConfig());
    const { status, data } = res;
    if (status === 200) {
      return data;
    }
  } catch (err) {
    window.location.href = "/login";
    console.log("errrrr");
  }
};

const handleWithdraw = async (url: string) => {
  console.log(url);
  const res = await axios.get(url, getConfig());
  console.log(res);
  return null;
};

export { getAccountInfo, handleWithdraw };
