import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Button from "../../components/Button";

export default function VerifyPhone() {
  const [code, setCode] = useState("");
  const [time, setTime] = useState(60);
  const navigate = useNavigate();

  const verifyCode = () => {
    navigate("/reset-password");
  };

  useEffect(() => {
    setInterval(() => {
      setTime(time - 1);
    }, 1000);
  }, [time]);

  return (
    <StyledWrapper>
      <p className="back-arrow" onClick={() => navigate(-1)}>
        {"<"}
      </p>
      <img className="my-5" src="./images/login-icons/message.png" alt="" />
      <h2>Verification</h2>
      <h3>We sent you a SMS code</h3>
      <p className="my-4">On number: +1 (778) 954 7377</p>
      <input
        className="my-input w-100"
        value={code}
        placeholder="xxxxxx"
        onChange={(e) => {
          setCode(e.target.value);
        }}
        type="text"
      />
      <div className="resend-wrapper">
        <p className="label mx-4">
          Not received? <span>Resend it</span>
        </p>
        <p className="timer">{time}</p>
      </div>

      <div className="text-center mt-5" onClick={verifyCode}>
        <Button text="Submit" onClick={() => navigate("/reset-password")} />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  padding: 40px;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  h3 {
    color: #a0a0a0;
    font-size: 20px;
    margin-top: 20px;
  }
  .label {
    text-align: right;
    padding-top: 20px;
    margin-left: 10px;
  }
  .resend-wrapper {
    position: relative;
    .timer {
      position: absolute;
      top: 20px;
      right: 0px;
    }
  }
  .back-arrow {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 40px;
  }
`;
