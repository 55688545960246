import React from "react";
import styled from "styled-components";
import { Menu, MenuItem } from "@mui/material";

export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleLanguageChange = (value: string) => {
    setAnchorEl(null);
    localStorage.setItem("language", value);
    window.location.reload();
  };

  return (
    <div>
      <div
        aria-expanded={open ? "true" : undefined}
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
      >
        <IconSelector src="./images/translate.png" alt="lang-selector" />
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={(e) => handleLanguageChange("zh")}>
          🇨🇳 简体中文
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("hk")}>
          🇭🇰 繁體中文
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("ar")}>
          🇸🇦 العربية/عربي
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("en")}>
          🇺🇸 English
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("fr")}>
          🇫🇷 français
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("vt")}>
          🇻🇳 TiếngViệt
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("th")}>
          🇹🇭 ภาษาไทย
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("jp")}>
          🇯🇵 日本語
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("kr")}>
          🇰🇷 한국어
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("in")}>
          🇮🇳 भारतीय भाषा
        </MenuItem>
        <MenuItem onClick={(e) => handleLanguageChange("ru")}>
          🇷🇺 русскийязык
        </MenuItem>
      </Menu>
    </div>
  );
}

const IconSelector = styled.img`
  width: 40px;
  height: 40px;
  :hover {
    cursor: pointer;
  }
`;
