/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/PageHeader";

import LayoutMobile from "../../components/LayoutMobile";

import { getAccountInfo } from "../../services/api";

import LogoutIcon from "@mui/icons-material/Logout";

import { emailToPhone } from "../../utils/emailToPhone";

const NavData = [
  {
    img: "/info",
    name: "My Information",
    path: "/myinfo",
  },
  {
    img: "/id",
    name: "ID Info",
    path: "",
  },
  {
    img: "/message",
    name: "Message",
    path: "",
  },
  {
    img: "/security",
    name: "Account Safety",
    path: "",
  },
  {
    img: "/legal",
    name: "Legal & Privacy",
    path: "",
  },
  {
    img: "/info",
    name: "About",
    path: "",
  },
];

export default function Profile() {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<any>(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAccountInfo();
      console.log(res);
      setUserData(res);
    };

    fetchData();
  }, []);

  return (
    <LayoutMobile>
      <PageHeader title="My Profile" />
      <StyledWrapper>
        <div className="text-center my-4">
          <img
            width={100}
            height={100}
            src="https://richlink.ca/images/logo.png"
            alt="avatar"
          />
          <h2>Chao Zhang</h2>
          <p>{userData && emailToPhone(userData?.email)}</p>
          <p className="verified my-2">Verified</p>
        </div>

        <div className="bar-wrapper">
          {NavData?.map((el: any) => (
            <div className="bar mx-4 d-flex flex-row justify-content-between align-items-center">
              <div
                className="d-flex flex-row justify-content-center align-items-center"
                onClick={() => navigate(el.path)}
              >
                <img
                  width={25}
                  src={`./images/profile-icons/${el.img}.png`}
                  alt="icon"
                />
                <p className="mx-3">{el.name}</p>
              </div>
              <p>{">"}</p>
            </div>
          ))}
          <div
            className="d-flex flex-row justify-content-start align-items-center mx-4 my-3"
            onClick={handleLogout}
          >
            <LogoutIcon width={30} style={{ color: "white" }} />
            <p className="logout mx-2">Log out</p>
          </div>
        </div>
      </StyledWrapper>
    </LayoutMobile>
  );
}

const StyledWrapper = styled.div`
  min-height: 100vh;
  color: #fff;
  /* background-image: url(./images/profile-icons/bg.png); */
  padding: 20px;

  .verified {
    color: #fff;
    background-color: #78d385;
    display: inline-block;
    padding: 4px 30px;
    border-radius: 20px;
  }
  .bar-wrapper {
    margin-top: 60px;
    p {
      color: #fff;
      font-size: 14px;
    }
    .bar {
      padding: 15px 0px;
      border-bottom: 1px solid gray;
    }
  }
`;
