import React, { useState } from "react";
import PageHeader from "../../components/PageHeader";
import Button from "@mui/material/Button";

import LayoutMobile from "../../components/LayoutMobile";

import { getAccountInfo } from "../../services/api";

import { useTranslation } from "react-i18next";

import styled from "styled-components";

export default function ChargePage() {
  const { t } = useTranslation();

  const [amount, setAmount] = useState<any>();

  const redirectUrl = "https://wallet.a99h.com/create-checkout-session";

  const handlePayment = async (num: number | undefined | null) => {
    const userData = await getAccountInfo();
    const url = `${redirectUrl}?uid=${userData?.user?.id}&amount=${
      num ? num : amount
    }`;
    window.location.href = url;
  };

  const data = [
    {
      rc: 1000,
      cad: 10,
    },
    {
      rc: 8800,
      cad: 88,
    },
    {
      rc: 38800,
      cad: 388,
    },
    {
      rc: 64800,
      cad: 648,
    },
    {
      rc: 99800,
      cad: 998,
    },
    {
      rc: 188800,
      cad: 1888,
    },
    {
      rc: 688800,
      cad: 6888,
    },
    {
      rc: 888800,
      cad: 8888,
    },
  ];

  return (
    <LayoutMobile>
      <PageHeader title={t(`navCharge`)} />
      <Wrapper>
        {/* <div className="text-center my-5">
          <img
            width={100}
            src="https://richlink.ca/images/blockchain/bitcoin-hand.png"
            alt="png"
          />
        </div> */}

        {/* <h3 className="mt-5 text-center">Please select:</h3> */}

        <p className="mx-3 my-4">{t(`chargeSelectPlan`)}</p>

        <div className="items-wrapper">
          {data.map((item: any) => (
            <div className="item" onClick={() => handlePayment(item.rc)}>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <p>{item.rc}</p>
                <img
                  className="mx-1"
                  width={15}
                  src="./images/coin.png"
                  alt="png"
                />
              </div>
              <p>$ {item.cad}</p>
            </div>
          ))}
        </div>

        <div className="d-flex flex-column">
          <p className="m-3">{t(`chargeInputAmount`)}</p>
          <input
            value={amount}
            onChange={(e: any) => {
              setAmount(e.target.value);
            }}
            className="my-input m-3"
            type="text"
          />
          <Button
            onClick={() => handlePayment(null)}
            className="m-3 bg-primary"
            variant="contained"
          >
            {t(`charge`)}
          </Button>
        </div>
      </Wrapper>
    </LayoutMobile>
  );
}

const Wrapper = styled.div`
  .items-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0px;
  }
  .item {
    margin: 20px;
    background-color: #6724fd;
    color: #fff;
    font-size: 20px;
    padding: 10px 20px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 60px;
    > p {
      font-size: 14px;
    }
  }
`;
