import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import axios from "axios";

import LanguageSelector from "../../components/LanguageSelector";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Select from "react-select";

import { API_PATH } from "../../services/api-path";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Checkbox from "@mui/material/Checkbox";

export default function Signup() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [stepNum, setStepNum] = useState(1);
  const [phoneNum, setPhoneNum] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isHided, setIsHided] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [timer, setTimer] = useState(60);
  const [countryData, setCountryData] = useState<any>();

  const PreCheckSignUp = () => {
    if (phoneNum === "") {
      NotificationManager.error("Phone number can not be empty.");
      return;
    }
    if (password === "") {
      NotificationManager.error("Password can not be empty.");
      return;
    }
    if (!isChecked) {
      NotificationManager.error("Please agree with the privacy policy.");
      return;
    }
    getVerificationCode();
  };

  useEffect(() => {
    if (stepNum === 2) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setTimer(60);
    }
  }, [stepNum, timer]);

  const getVerificationCode = async () => {
    let res = await axios.get(
      "https://wallet.a99h.com:446/getCode/" + phoneNum
    );
    if (res.data.status === "ok") {
      setStepNum(2);
      setTimer(60);
      NotificationManager.success(
        "SMS verification code has been sent, please check your phone."
      );
    } else {
      NotificationManager.error(
        "The SMS verification code failed to send, please confirm the global valid mobile phone number."
      );
    }
  };

  const validateVerificationCode = async () => {
    if (verificationCode === "") {
      NotificationManager.error("Verification code must be filled");
      return;
    }
    let res = await axios.get("https://wallet.a99h.com:446/checkCode", {
      params: {
        phone: phoneNum,
        code: verificationCode,
      },
    });
    if (res.data.status === "ok") {
      NotificationManager.success("Verification code successful");
      handleSignup();
    } else {
      NotificationManager.error("Verification code error");
    }
  };

  const getCountriesData = async () => {
    const { data } = await axios.get("https://restcountries.com/v3.1/all");
    const items = [];
    for (const country of data) {
      let callingCode = "";
      if (country.idd.root) callingCode += country.idd.root;
      if (country.idd.suffixes && country.idd.suffixes.length === 1) {
        callingCode += country.idd.suffixes[0];
      }
      callingCode += " " + country.flag + " ";
      callingCode += country.name.common;
      const item = {
        label: callingCode,
        value: callingCode,
      };
      items.push(item);
    }
    setCountryData(items);
  };

  const handleSignup = async () => {
    try {
      const url = `${API_PATH}/v2/account/authenticate/email?create=true`;
      const res = await axios({
        method: "post",
        url: url,
        auth: {
          username: "defaultkey",
          password: "",
        },
        data: {
          email: phoneNum + "@richlinkwallet.com",
          password: password,
        },
      });
      console.log(res);
      if (res.status === 200) {
        const token = res.data.token;
        localStorage.setItem("token", token);
        navigate("/");
        window.location.reload();
      } else {
        alert("error");
      }
    } catch (error) {
      alert("Username or password is incorrect!");
      console.log(error);
    }
  };

  useEffect(() => {
    getCountriesData();
  }, []);

  return (
    <Wrapper>
      <h5 className="title">{t(`log.signup`)}</h5>
      <div className="language-selector">
        <LanguageSelector />
      </div>
      {stepNum === 1 ? (
        <div className="body">
          <div className="mb-3 position-relative">
            <label>{t(`log.phoneNumber`)}</label>
            <br />
            <div className="selector-wrapper">
              <Select options={countryData} />
            </div>
            <input
              autoComplete="off"
              className="my-input"
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.target.value);
              }}
              type="text"
            />
          </div>
          <div className="position-relative" style={{ width: "300px" }}>
            <label>{t(`log.password`)}</label>
            <br />
            <input
              autoComplete="off"
              className="my-input"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={isHided ? "password" : "text"}
            />
            {isHided ? (
              <VisibilityOffIcon
                className="password-eye"
                onClick={() => setIsHided(false)}
              />
            ) : (
              <VisibilityIcon
                className="password-eye"
                onClick={() => setIsHided(true)}
              />
            )}
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center py-2">
            <Checkbox
              style={{ color: "#fff" }}
              size="small"
              checked={isChecked}
              onClick={() => {
                setIsChecked(!isChecked);
              }}
            />
            <p>{t(`log.agreement`)}</p>
          </div>
          <div className="text-center mt-5" onClick={PreCheckSignUp}>
            <Button text={t(`log.signup`)} />
          </div>
        </div>
      ) : (
        <div className="body">
          <div className="position-relative">
            <label>{t(`log.smsConfirmation`)}</label>
            <br />
            <input
              autoComplete="off"
              className="my-input"
              value={verificationCode}
              onChange={(e) => {
                setVerificationCode(e.target.value);
              }}
              type="text"
            />
          </div>
          <label>
            {t(`log.noCode`)}
            {timer > 0 ? (
              <button className="resend" disabled={true}>
                {t(`log.resend`)} {timer}s
              </button>
            ) : (
              <button className="resend" onClick={getVerificationCode}>
                {t(`log.resend`)}
              </button>
            )}
          </label>
          <div className="text-center mt-5" onClick={validateVerificationCode}>
            <Button text={t(`log.confirm`)} />
            <p className="my-4" onClick={() => setStepNum(1)}>
              {"< "}
              {t(`log.back`)}
            </p>
          </div>
        </div>
      )}
      <div className="footer">
        <p>
          {t(`log.haveAccount`)}
          <span>
            <a href="/login" className="mx-1">
              {t(`log.login`)}
            </a>
          </span>
        </p>
      </div>
      <NotificationContainer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-size: 14px;
  color: white;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(./images/signup-bg.png);
  .title {
    position: absolute;
    top: 60px;
    left: 40px;
    font-size: 30px;
    font-weight: 900;
  }
  .language-selector {
    position: absolute;
    top: 58px;
    right: 40px;
  }
  .body {
    position: absolute;
    bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    label {
      margin: 10px 0px;
    }
    .selector-wrapper {
      width: 300px;
      color: black;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      border-color: #fff;
      outline: 0px;
      border: 0px;
      border-radius: 5px;
      padding: 8px;
      color: black;
    }
    .resend {
      border: 0px;
      background-color: #fff;
      border-radius: 5px;
      margin: 0px 10px;
      padding: 2px 5px;
      margin: 10px;
    }
  }
  .footer {
    position: absolute;
    bottom: 20px;
    width: 100vw;
    text-align: center;
  }
`;
