import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import PageHeader from "../../components/PageHeader";

import LayoutMobile from "../../components/LayoutMobile";

export default function BillPage() {
  const navigate = useNavigate();
  const data = [
    {
      time: "Today",
      data: [
        {
          name: "John Peterson",
          amount: 50,
        },
      ],
    },
    {
      time: "July 2, 2022",
      data: [
        {
          name: "Taxi",
          amount: -8.99,
        },
        {
          name: "ATM",
          amount: -50,
        },
        {
          name: "Taxi",
          amount: 8.99,
        },
        {
          name: "Online Store",
          amount: -466.95,
        },
      ],
    },
    {
      time: "5 July, 2022",
      data: [
        {
          name: "Shoes Company",
          amount: 299.99,
        },
        {
          name: "Grocery Limited",
          amount: 60.28,
        },
      ],
    },
  ];

  const isPositive = (amount: number) => {
    return amount >= 0;
  };

  const name = "Chao Zhang";

  return (
    <StyledWrapper>
      <LayoutMobile>
        <PageHeader title="Bills" />
        <div className="header-wrapper">
          <div>
            <h5>Good afternoon,</h5>
            <h5>{name} !</h5>
          </div>
          <img
            src="https://richlink.ca/images/logo.png"
            onClick={() => navigate("/profile")}
            style={{ borderRadius: "50%" }}
            width={70}
            height={70}
            alt="avatar"
          />
        </div>
        <div className="d-flex flex-row justify-content-between mt-3">
          <p>Transactions</p>
          <div className="d-flex flex-row">
            <p className="mx-2">Date</p>
            <select name="" id="">
              <option value="dsd">Last 30 days</option>
              <option value="dsd">Last 90 days</option>
              <option value="dsd">Last 120 days</option>
              <option value="dsd">2022</option>
            </select>
          </div>
        </div>
        <div>
          {data?.map((el: any) => (
            <div key={el.time} className="my-5">
              <p>{el.time}</p>
              {el.data.map((record: any) => (
                <div
                  className="record-bar"
                  onClick={() => {
                    navigate("/transitions");
                  }}
                >
                  <p>{record.name}</p>
                  <p
                    style={{
                      color: isPositive(record.amount) ? "#c2adef" : "#fff",
                    }}
                  >
                    {isPositive(record.amount)
                      ? `$${record.amount}`
                      : `- $${(record.amount + "").slice(1)}`}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </LayoutMobile>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
  }

  .record-bar {
    background: #c6c6c646;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
    margin: 20px 10px;
  }
`;
