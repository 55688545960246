// import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function PageHeader(props: any) {
  // const navigate = useNavigate();
  return (
    <Wrapper>
      {/* <span onClick={() => navigate(-1)}>{"<"}</span> */}
      <p className="m-2 mp-0">{props.title}</p>
      <hr />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  p {
    font-size: 18px;
    margin: 10px;
  }
  hr {
    border: 1px solid white;
  }
  span {
    font-size: 20px;
    position: absolute;
    left: 20px;
    top: 0px;
  }
`;
