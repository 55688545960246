import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LanguageSelector from "../../components/LanguageSelector";

import Button from "../../components/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useTranslation } from "react-i18next";

import Select from "react-select";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("7789577977");
  const [password, setPassword] = useState("Mima123.");
  const [isHided, setIsHided] = useState(true);
  const [countryData, setCountryData] = useState<any>();

  const { t } = useTranslation();

  const getCountriesData = async () => {
    const { data } = await axios.get("https://restcountries.com/v3.1/all");
    const items = [];
    for (const country of data) {
      let callingCode = "";
      if (country.idd.root) callingCode += country.idd.root;
      if (country.idd.suffixes && country.idd.suffixes.length === 1) {
        callingCode += country.idd.suffixes[0];
      }
      callingCode += " " + country.flag + " ";
      callingCode += country.name.common;
      const item = {
        label: callingCode,
        value: callingCode,
      };
      items.push(item);
    }
    setCountryData(items);
  };

  const handleLogin = async () => {
    try {
      const url =
        "https://wallet.a99h.com:444/v2/account/authenticate/email?create=false";
      const res = await axios({
        method: "post",
        url: url,
        auth: {
          username: "defaultkey",
          password: "",
        },
        data: {
          email: email + "@richlinkwallet.com",
          password: password,
        },
      });
      console.log(res);
      if (res.status === 200) {
        const token = res.data.token;
        localStorage.setItem("token", token);
        navigate("/");
        window.location.reload();
      } else {
        NotificationManager.error("error");
      }
    } catch (error) {
      NotificationManager.error("Username or password is incorrect!");
      console.log(error);
    }
  };

  const handleHidingPassword = () => {
    let res: string = "";
    for (const c of password) {
      res += c;
    }
    return res;
  };

  useEffect(() => {
    getCountriesData();
  }, []);

  // useEffect(() => {
  //   getCountriesData();
  //   const nums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  //   const strArr = password
  //     .split("")
  //     .filter((char: string) => nums.includes(char));
  //   console.log(strArr);

  //   if (strArr.length > 3) {
  //     let strHead = password.slice(0, 3);
  //     let strTail = password.slice(4);
  //     const str = `(${strHead}) - ${strTail}`;
  //     setPassword(str);
  //   }
  // }, [password]);

  return (
    <>
      <Wrapper>
        <h5 className="title">{t(`log.login`)}</h5>
        <div className="language-selector">
          <LanguageSelector />
        </div>
        <div className="body">
          <div className="mb-3">
            <label>{t(`log.phoneNumber`)}</label>
            <div className="selector-wrapper">
              <Select options={countryData} />
            </div>
            <input
              autoComplete="off"
              type="number"
              className="input-number"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="position-relative">
            <label>{t(`log.password`)}</label>
            <br />
            <input
              autoComplete="off"
              className="input-password"
              value={isHided ? handleHidingPassword() : password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={isHided ? "password" : "text"}
            />
            <div
              onClick={() => {
                setIsHided(!isHided);
              }}
            >
              {isHided ? (
                <VisibilityOffIcon
                  className="password-eye"
                  onClick={() => setIsHided(false)}
                />
              ) : (
                <VisibilityIcon
                  className="password-eye"
                  onClick={() => setIsHided(true)}
                />
              )}
            </div>
          </div>
          <p className="text-left w-100 my-4 px-5">
            <span onClick={() => navigate("/forget-password-phone")}>
              {t(`log.forgetPassword`)}
            </span>
          </p>

          <div className="text-center mt-5" onClick={handleLogin}>
            <Button text={t(`log.login`)} />
          </div>
        </div>
        <div className="footer">
          <p>
            {t(`log.noAccount`)}?
            <span>
              <a href="/signup" className="mx-1">
                {t(`log.signup`)}
              </a>
            </span>
          </p>
        </div>
        <NotificationContainer />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  font-size: 14px;
  color: white;
  position: relative;
  height: 100vh;
  background-image: url(./images/login-bg-new.png);
  .title {
    position: absolute;
    top: 60px;
    left: 40px;
    font-size: 30px;
    font-weight: 900;
  }
  .language-selector {
    position: absolute;
    top: 58px;
    right: 40px;
  }
  .body {
    position: absolute;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    label {
      margin: 10px 0px;
    }
    .selector-wrapper {
      width: 300px;
      color: black;
      margin-bottom: 10px;
    }
    input {
      width: 300px;
      border-color: #fff;
      outline: 0px;
      border: 0px;
      border-radius: 5px;
      padding: 8px;
      color: black;
    }
  }
  .footer {
    position: absolute;
    bottom: 20px;
    width: 100vw;
    text-align: center;
  }
`;
