import React from "react";

import styled from "styled-components";

import { useNavigate } from "react-router-dom";

export default function WithdrawSendSwitch(props: any) {
  const navigate = useNavigate();

  const getStyle = (value: string) => {
    if (props.currentItem === value) {
      return {
        fontWeight: 700,
        color: "#6724fd",
      };
    } else {
      return {
        fontWeight: 300,
        color: "#fff",
      };
    }
  };

  return (
    <StyledWrapper>
      <p
        onClick={() => {
          navigate("/withdraw");
        }}
        style={getStyle("withdraw")}
      >
        Withdraw
      </p>
      <p
        onClick={() => {
          navigate("/send");
        }}
        style={getStyle("send")}
      >
        Send
      </p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  text-align: center;
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  p {
    width: 50%;
  }
`;
