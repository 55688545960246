import styled from "styled-components";

export default function Button(props: any) {
  return <ButtonWrapper color={props.color}>{props.text}</ButtonWrapper>;
}

const ButtonWrapper = styled.button`
  background-color: red;
  color: #000000;
  background-color: #fff;
  padding: 8px 30px;
  outline: 0px;
  border: 0px;
  border-radius: 50px;
`;
