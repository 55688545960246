import React, { useState } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

export default function ForgetPasswordPhone() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const sendCodetoPhone = () => {
    navigate("/verify-phone");
  };

  return (
    <StyledWrapper>
      <p className="back-arrow" onClick={() => navigate(-1)}>
        {"<"}
      </p>
      <img className="my-5" src="./images/login-icons/phone.png" alt="" />
      <h2>Enter your phone</h2>
      <p className="my-4">
        You will receive a 4 digits code for phone number verification
      </p>
      <div className="d-flex flex-row justify-content-center align-items-center py-4">
        <a href="/forget-password-email">
          <p className="mx-4">Email</p>
        </a>
        <p className="mx-4" style={{ fontWeight: 700 }}>
          Phone Number
        </p>
      </div>
      <br />
      <p className="input-title py-2 px-3">🇨🇦 Canada</p>
      <div className="input-wrapper">
        <label className="phone-label">
          +1 <span className="mx-2">|</span>{" "}
        </label>
        <input
          className="my-input w-100 phone-input"
          value={email}
          placeholder="Phone number"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="text"
        />
      </div>
      <div className="text-center mt-5" onClick={sendCodetoPhone}>
        <Button text="Continue" />
      </div>
      <p className="back-to-login" onClick={() => navigate("/login")}>
        Back to Login
      </p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  padding: 40px;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  > .label {
    text-align: left;
    padding: 10px 0px;
  }
  .back-arrow {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 40px;
  }
  .back-to-login {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 50px);
  }
  .input-title {
    background-color: #fff;
    color: black;
    text-align: left;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #ffffffe1;
  }
  a {
    text-decoration: none;
  }
  .input-wrapper {
    position: relative;
  }
  .phone-input {
    padding-left: 55px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .phone-label {
    position: absolute;
    color: black;
    top: 9px;
    left: 10px;
  }
`;
