import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "./redux/store";
import Routing from "./utils/routing";
import "./index.css";
import "react-notifications/lib/notifications.css";
import "react-loading-skeleton/dist/skeleton.css";

import "./translations/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routing />
    </Provider>
  </React.StrictMode>
);
