import React from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import PersonIcon from "@mui/icons-material/Person";

import HomeIcon from "@mui/icons-material/Home";

import PaidIcon from "@mui/icons-material/Paid";

import { useTranslation } from "react-i18next";

// import OutboundIcon from "@mui/icons-material/Outbound";

const BottomNav = () => {
  const navigate = useNavigate();

  const currentPath = window.location.href;
  let arr = currentPath.split("/");

  let name = arr[arr.length - 1];

  const { t } = useTranslation();

  const pageJump = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      {name !== "login" && (
        <NavWrapper className="text-center d-flex flex-row justify-content-around align-items-center p-3">
          {/* <div onClick={() => pageJump("/")}>
            <AccountBalanceWalletIcon />
            <p>Wallet</p>
          </div> */}

          <div onClick={() => pageJump("/")}>
            <HomeIcon />
            <p>{t(`navHome`)}</p>
          </div>

          <div onClick={() => pageJump("/charge")}>
            <AccountBalanceWalletIcon />
            <p>{t(`navCharge`)}</p>
          </div>

          <div onClick={() => pageJump("/withdraw")}>
            <PaidIcon />
            <p>{t(`navWithdraw`)}</p>
          </div>

          {/* 
          <div onClick={() => pageJump("/withdraw")}>
            <img width={35} src="./images/money-transfer.png" alt="icon" />
          </div> */}

          {/* <div onClick={() => pageJump("/profile")}>
            <PersonIcon />
            <p>Profile</p>
          </div> */}
        </NavWrapper>
      )}
    </div>
  );
};

const NavWrapper = styled.div<any>`
  width: 100vw;
  background-color: black;
  /* img {
    width: 20px;
    height: 20px;
  } */
  p {
    font-size: 12px;
    padding-top: 5px;
  }
`;

export default BottomNav;
