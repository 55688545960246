import React from "react";
import PageHeader from "../../components/PageHeader";

import { Counter } from "../../redux/features/counter/Counter";

export default function Pay() {
  return (
    <>
      <PageHeader title="Pay" />
      <h1>Pay</h1>
      <Counter />
      <p>Pay</p>
    </>
  );
}
