import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Button from "../../components/Button";

export default function CheckEmail() {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const verifyCode = () => {
    navigate("/reset-password");
  };

  return (
    <StyledWrapper>
      <p className="back-arrow" onClick={() => navigate(-1)}>
        {"<"}
      </p>
      <img className="my-5" src="./images/login-icons/document.png" alt="" />
      <h2>Check your email</h2>
      <p className="my-4">
        We have sent a password recovery instruction to your email
      </p>
      <p className="label">Verification Code</p>
      <input
        className="my-input w-100"
        value={code}
        placeholder="xxxxxx"
        onChange={(e) => {
          setCode(e.target.value);
        }}
        type="text"
      />
      <div className="text-center mt-5" onClick={verifyCode}>
        <Button text="Submit" />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  padding: 40px;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  > .label {
    text-align: left;
    padding: 10px 0px;
  }
  .back-arrow {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 40px;
  }
`;
