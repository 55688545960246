import React from "react";

import styled from "styled-components";

import BottomNav from "./BottomNav";

export default function LayoutMobile({ children }: any) {
  return (
    <LayoutWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <NavWrapper>
        <BottomNav />
      </NavWrapper>
    </LayoutWrapper>
  );
}

const LayoutWrapper = styled.div`
  position: relative;
  max-width: 540px;
  min-height: 100vh;
  background-color: black;
  color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  padding-bottom: 120px;
`;

const NavWrapper = styled.div`
  position: fixed;
  bottom: 0px;
`;
