import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Button from "../../components/Button";

export default function PasswordUpdated() {
  const navigate = useNavigate();

  return (
    <StyledWrapper>
      <p className="back-arrow" onClick={() => navigate(-1)}>
        {"<"}
      </p>
      <h2>
        Password <br />
        Updated
      </h2>
      <img width={80} className="my-5" src="./images/correct.png" alt="" />
      <p className="my-4">Your password has been updated!</p>
      <div className="text-center mt-5" onClick={() => navigate("/home")}>
        <Button text="Submit" />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  padding: 40px;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  h2 {
    margin-top: 80px;
  }
  > .label {
    text-align: left;
    padding: 10px 0px;
  }
  .back-arrow {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 40px;
  }
`;
