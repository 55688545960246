import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/PageHeader";

import LanguageSelector from "../../components/LanguageSelector";

import { useTranslation } from "react-i18next";

// import styled from "styled-components";

import Button from "@mui/material/Button";

import LayoutMobile from "../../components/LayoutMobile";

import { getAccountInfo } from "../../services/api";

// import Header from "../../components/Header";

import { emailToPhone } from "../../utils/emailToPhone";

export default function Homepage() {
  const [curNav, setCurNav] = useState<string>("Currency");

  const [data, setData] = useState<any>(null);
  const [walletData, setWalletData] = useState<any>();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const isActive = (navItem: string) => {
    if (navItem === curNav) {
      return {
        borderBottom: "1px solid grey",
        fontWeight: 700,
      };
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAccountInfo();
      setData(res);
      setWalletData(JSON.parse(res?.wallet));
    };

    const currentUrl = window.location.href;
    const tag = "?token=";
    if (currentUrl.includes(tag)) {
      const token = currentUrl.split(tag)[1];
      localStorage.setItem("token", token);
      // window.location.reload();
      fetchData();
    } else {
      fetchData();
    }
  }, []);

  return (
    <LayoutMobile>
      <PageHeader title={t(`navHome`)} />
      <div className="d-flex flex-row justify-content-between align-items-center p-4">
        <div>
          <p style={{ fontSize: "10px", color: "grey" }}>
            {t(`homeWelcomeBack`)}
          </p>
          <p className="my-2">
            {t(`homePhone`)}: {data && emailToPhone(data?.email)}
          </p>
          <p className="my-2">
            {t(`homeUsername`)}: {data && emailToPhone(data?.user?.username)}
          </p>
        </div>
        <LanguageSelector />
      </div>
      {/* nav bar  */}
      <div className="row text-center p-3 mb-2 d-none">
        <div className="col-6" onClick={() => setCurNav("Currency")}>
          <p className="py-1" style={isActive("Currency")}>
            Currency
          </p>
        </div>
        <div className="col-6" onClick={() => setCurNav("Cards")}>
          <p className="py-1" style={isActive("Cards")}>
            Cards
          </p>
        </div>
      </div>
      {/* currency  */}
      <div className="text-center p-3">
        {curNav === "Currency" ? (
          <div className="position-relative">
            <h2
              className="position-absolute"
              style={{ top: "40px", left: "80px" }}
            >
              R Coins: <br />
              {walletData ? walletData.RC : "0"}
            </h2>
            <img width={300} src="./images/homebg.png" alt="bg" />
          </div>
        ) : curNav === "Tokens" ? (
          <img width={300} src="./images/homebg.png" alt="bg" />
        ) : (
          <p style={{ width: 300, height: 120 }}>No Card Image</p>
        )}
        {/* <div className="row" style={{ marginTop: "-20px" }}>
          <IconWrapper className="col-4" onClick={() => handleNavClick("bill")}>
            <img width={50} src="./images/icon1.png" alt="bg" />
            <p>Bill</p>
          </IconWrapper>
          <IconWrapper
            className="col-4"
            onClick={() => handleNavClick("withdraw")}
          >
            <img width={50} src="./images/icon2.png" alt="bg" />
            <p>Withdraw</p>
          </IconWrapper>
          <IconWrapper
            className="col-4"
            onClick={() => handleNavClick("charge")}
          >
            <img width={50} src="./images/icon3.png" alt="bg" />
            <p>Charge</p>
          </IconWrapper>
        </div> */}
        <img width={340} src="./images/hot-topic.png" alt="bg" />

        <Button
          className="my-5 bg-light text-dark"
          size="large"
          variant="contained"
          onClick={handleLogout}
        >
          {t(`logout`)}
        </Button>
      </div>
    </LayoutMobile>
  );
}

// const IconWrapper = styled.div<any>`
//   svg {
//     color: ${(props) => props.color};
//     background-color: ${(props) => props.bgColor};
//     padding: 10px;
//     width: 45px;
//     height: 45px;
//     border-radius: 50%;
//   }
//   p {
//     font-size: 12px;
//     padding: 10px 0px;
//     font-weight: 300;
//   }
// `;
