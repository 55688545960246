import React, { useState } from "react";
import styled from "styled-components";
import PageHeader from "../../components/PageHeader";

import Button from "@mui/material/Button";

import { useParams } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import LayoutMobile from "../../components/LayoutMobile";

export default function SendFriendsPage() {
  const [isHidden, setHiddenStatus] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(16420);
  const { name, email } = useParams();
  const friendName = name?.split(":")[1];
  const friendEmail = email?.split(":")[1];
  const robohash = "https://robohash.org/";

  return (
    <LayoutMobile>
      <StyledWrapper>
        <PageHeader title="Withdraw" />
        <div className="money-wrapper">
          <div className="amount-wrapper">
            <h1>$</h1>
            <input
              value={amount}
              onChange={(e) => {
                setAmount(Number(e.target.value));
              }}
            />
          </div>
          <div
            className="visibility-wrapper"
            onClick={() => setHiddenStatus(!isHidden)}
          >
            {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </div>
          <p>{`You have $${isHidden ? "*****" : "213213"} available`}</p>
        </div>
        <div className="card-wrapper">
          <img src={robohash + friendEmail} alt="" />
          <div>
            <p>To</p>
            <p>{friendName}</p>
            <p>{friendEmail}</p>
          </div>
          <p className="icon">{">"}</p>
        </div>
        <Button
          className="m-4 bg-light text-dark"
          size="large"
          variant="contained"
        >
          Send
        </Button>
      </StyledWrapper>
    </LayoutMobile>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .amount-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 34px;
      margin: 15px 10px 15px -20px;
    }
    input {
      font-size: 30px;
      outline: 0px;
      padding: 5px 10px;
      width: 100%;
    }
  }
  .money-wrapper {
    text-align: center;
    margin: 40px;
    .visibility-wrapper {
      margin: 10px;
    }
    h1 {
      font-size: 40px;
    }
    span {
      margin: 10px;
    }
  }
  .card-wrapper {
    background: #6724fd;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .icon {
      font-size: 30px;
    }
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 5px;
    }
  }
`;
