import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Button from "../../components/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const navigate = useNavigate();

  const resetPassword = () => {
    navigate("/password-updated");
  };

  return (
    <StyledWrapper>
      <p className="back-arrow" onClick={() => navigate(-1)}>
        {"<"}
      </p>
      <img className="my-5" src="./images/login-icons/lock.png" alt="" />
      <h2>Create new password</h2>
      <p className="my-4">
        Your new password must be different from previously used password
      </p>

      <div className="mb-3 position-relative">
        <p className="label">Reset Password</p>
        <input
          className="my-input w-100"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="text"
        />
        <RemoveRedEyeIcon className="password-eye" />
      </div>

      <div className="mb-3 position-relative">
        <p className="label">Confirm Password</p>
        <input
          className="my-input w-100"
          value={password2}
          onChange={(e) => {
            setPassword2(e.target.value);
          }}
          type="text"
        />
        <RemoveRedEyeIcon className="password-eye" />
      </div>

      <div className="text-center mt-5" onClick={resetPassword}>
        <Button text="Submit" />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  padding: 40px;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  .label {
    text-align: left;
    padding: 10px 0px;
  }
  .back-arrow {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 40px;
  }
  .back-to-login {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 50px);
  }
`;
