import { BrowserRouter, useRoutes } from "react-router-dom";

import Home from "../pages/main/Home";
import Bill from "../pages/main/Bill";
import Send from "../pages/main/Send";
import Withdraw from "../pages/main/Withdraw";
import Charge from "../pages/main/Charge";
import Login from "../pages/login/Login";
import Signup from "../pages/login/Signup";
import Profile from "../pages/profile/Profile";
import Pay from "../pages/main/Pay";
import Transitions from "../pages/main/Transitions";
import SendFriends from "../pages/main/SendFriends";
import NewProfile from "../pages/profile/MyInfo";

// login
import CheckEmail from "../pages/login/CheckEmail";
import ForgetPasswordEmail from "../pages/login/ForgetPasswordEmail";
import ForgetPasswordPhone from "../pages/login/ForgetPasswordPhone";
import ResetPassword from "../pages/login/ResetPassword";
import VerifyPhone from "../pages/login/VerifyPhone";
import PasswordUpdated from "../pages/login/PasswordUpdated";
import MyInfo from "../pages/profile/MyInfo";

// cover
import Cover from "../pages/others/Cover";

const Routes = () => {
  let routes = useRoutes([
    {
      path: "/cover",
      element: <Cover />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/bill",
      element: <Bill />,
    },
    {
      path: "/send",
      element: <Send />,
    },

    {
      path: "/send-friends",
      element: <SendFriends />,
    },
    {
      path: "/send-friends/:name/:email",
      element: <SendFriends />,
    },

    {
      path: "/withdraw",
      element: <Withdraw />,
    },
    {
      path: "/charge",
      element: <Charge />,
    },
    {
      path: "/pay",
      element: <Pay />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/newprofile",
      element: <NewProfile />,
    },
    {
      path: "/myinfo",
      element: <MyInfo />,
    },
    {
      path: "/transitions",
      element: <Transitions />,
    },
    // login related
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    // new added
    {
      path: "/forget-password-email",
      element: <ForgetPasswordEmail />,
    },
    {
      path: "/forget-password-phone",
      element: <ForgetPasswordPhone />,
    },
    {
      path: "/check-email",
      element: <CheckEmail />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/verify-phone",
      element: <VerifyPhone />,
    },
    {
      path: "/password-updated",
      element: <PasswordUpdated />,
    },
  ]);

  return routes;
};

export default function Routing() {
  return (
    <BrowserRouter>
      <div className="global-wrapper">
        <Routes />
      </div>
      <div className="switch-to-mobile-wrapper">
        <img width={150} className="my-3" src="./images/logo.png" alt="logo" />
        <p className="switch-to-mobile-content-wrapper">
          Please use your phone to get access to <br /> Richlink Wallet. Thank
          you!
        </p>
      </div>
    </BrowserRouter>
  );
}
