import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../components/PageHeader";

import axios from "axios";

import Button from "@mui/material/Button";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import LayoutMobile from "../../components/LayoutMobile";

import { useTranslation } from "react-i18next";

// import WithdrawSendSwitch from "../../components/WithdrawSendSwitch";

import { getAccountInfo } from "../../services/api";

export default function WithdrawPage() {
  const [isHidden, setHiddenStatus] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [data, setData] = useState<any>(null);

  const { t } = useTranslation();

  const handleWithdraw = async () => {
    const redirectUrl = "https://wallet.a99h.com/payout";
    const url = `${redirectUrl}?uid=${data?.user?.id}&amount=${amount}`;
    try {
      const res = await axios.get(url);
      console.log(res);
    } catch (error) {
      window.location.reload();
    }
    window.location.reload();
  };

  const getCadAmount = () => {
    const wallet = JSON.parse(data.wallet);
    return wallet?.RC;
  };

  useEffect(() => {
    const getUserData = async () => {
      const res = await getAccountInfo();
      setData(res);
    };

    getUserData();
  }, []);

  return (
    <LayoutMobile>
      <PageHeader title={t(`navWithdraw`)} />
      <StyledWrapper>
        {/* <WithdrawSendSwitch currentItem="withdraw" /> */}
        <div className="money-wrapper">
          <h1>
            {isHidden ? "*****" : data && getCadAmount()} <br /> R Coins
          </h1>
          <div
            className="visibility-wrapper"
            onClick={() => setHiddenStatus(!isHidden)}
          >
            {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </div>
          <div className="amount-wrapper">
            <input
              value={amount}
              onChange={(e) => {
                setAmount(Number(e.target.value));
              }}
            />
          </div>
          {/* <p>{`You have $ ${
            isHidden ? "*****" : data && getCadAmount()
          } R Coins`}</p> */}
        </div>
        <div className="card-wrapper">
          <CreditCardIcon style={{ color: "#fff", fontSize: "40px" }} />
          <div>
            <p className="mx-5">{t(`withdrawToYourCard`)}</p>
          </div>
          <p className="icon">{">"}</p>
        </div>
        <Button
          className="m-4 bg-light text-dark"
          size="large"
          variant="contained"
          onClick={handleWithdraw}
        >
          {t(`withdraw`)}
        </Button>
      </StyledWrapper>
    </LayoutMobile>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .amount-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 34px;
      margin: 15px 10px 15px -20px;
    }
    input {
      font-size: 30px;
      outline: 0px;
      padding: 5px 10px;
      width: 200px;
    }
  }
  .money-wrapper {
    text-align: center;
    margin: 40px;
    .visibility-wrapper {
      margin: 10px;
    }
    h1 {
      font-size: 40px;
    }
    span {
      margin: 10px;
    }
  }
  .card-wrapper {
    background: #6724fd;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .icon {
      font-size: 30px;
    }
    > p {
      word-break: break-word;
    }
  }
`;
