import React from "react";
import PageHeader from "../../components/PageHeader";
import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function Transitions() {
  const navigate = useNavigate();

  return (
    <div>
      <PageHeader title="Transitions" />
      <div className="d-flex flex-column">
        <div className="text-center">
          <CheckCircleOutlineIcon
            style={{
              margin: "30px",
              width: "100px",
              height: "100px",
              color: "#fff",
            }}
          />
          <p className="my-3">Your Transition is Done!</p>
          <p className="my-3">Blockchain Transition Record:</p>
          <a href="https://ethermine.org/miners/70c35ec74cf2ff5ec23bfedd4afce97eca4450b5/dashboard">
            <p className="p-3">0x48317d08F83B1D4F2b 62E8165D3E20eA64Ee00C0</p>
          </a>
        </div>
        <Button
          onClick={() => {
            navigate("/pay");
          }}
          className="m-3 bg-primary"
          variant="contained"
          size="large"
        >
          Make Another Transition
        </Button>
        <Button
          className="m-3 bg-light text-dark"
          variant="contained"
          size="large"
          onClick={() => {
            navigate("/");
          }}
        >
          Return to Home
        </Button>
      </div>
    </div>
  );
}
