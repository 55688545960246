import React from "react";

export default function Cover() {
  return (
    <div>
      <img
        style={{
          width: "100vw",
          height: "100vh",
        }}
        src="./images/cover.png"
        alt=""
      />
    </div>
  );
}
