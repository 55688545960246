import React from "react";
import PageHeader from "../../components/PageHeader";

import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import WithdrawSendSwitch from "../../components/WithdrawSendSwitch";
import LayoutMobile from "../../components/LayoutMobile";

export default function Send() {
  const robohash = "https://robohash.org/";

  const userData = [
    {
      name: "Simon You",
      email: "simonyou@richlink.ca",
    },
    {
      name: "Bill Liang",
      email: "billliang@richlink.ca",
    },
    {
      name: "Cynthia Gu",
      email: "cynthiagu@richlink.ca",
    },
    {
      name: "Jane",
      email: "jane@richlink.ca",
    },
    {
      name: "Chan",
      email: "chan@richlink.ca",
    },
    {
      name: "Mike",
      email: "mike@richlink.ca",
    },
    {
      name: "Richlink Robot 1",
      email: "richrobot1@richlink.ca",
    },
    {
      name: "Richlink Robot 2",
      email: "richrobot2@richlink.ca",
    },
    {
      name: "Richlink Robot 3",
      email: "richrobot3@richlink.ca",
    },
    {
      name: "Richlink Robot 4",
      email: "richrobot4@richlink.ca",
    },
    {
      name: "Richlink Robot 5",
      email: "richrobot5@richlink.ca",
    },
  ];

  const navigate = useNavigate();

  return (
    <LayoutMobile>
      <StyledWraper>
        <PageHeader title="Send" />
        <WithdrawSendSwitch />
        {userData?.map((el: any) => (
          <div
            className="user-wrapper"
            onClick={() => {
              navigate(`/send-friends/name:${el.name}/email:${el.email}`);
            }}
          >
            <img src={robohash + el.email} alt="avatar" />
            <div>
              <p>{el.name}</p>
              <p>{el.email}</p>
            </div>
          </div>
        ))}
      </StyledWraper>
    </LayoutMobile>
  );
}

const StyledWraper = styled.div`
  .user-wrapper {
    background: #26293b;
    margin: 20px;
    padding: 10px 20px;
    border-radius: 20px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 20px;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;
