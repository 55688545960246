import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arTranslation from "./ar.json";
import enTranslation from "./en.json";
import frTranslation from "./fr.json";
import hkTranslation from "./hk.json";
import inTranslation from "./in.json";
import jpTranslation from "./jp.json";
import krTranslation from "./kr.json";
import ruTranslation from "./ru.json";
import thTranslation from "./th.json";
import vtTranslation from "./vt.json";
import zhTranslation from "./zh.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ar: {
    translation: arTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  hk: {
    translation: hkTranslation,
  },
  ru: {
    translation: ruTranslation,
  },
  th: {
    translation: thTranslation,
  },
  vt: {
    translation: vtTranslation,
  },
  en: {
    translation: enTranslation,
  },
  zh: {
    translation: zhTranslation,
  },
  in: {
    translation: inTranslation,
  },
  jp: {
    translation: jpTranslation,
  },
  kr: {
    translation: krTranslation,
  },
};

const getTheDefaultLanguage = () => {
  let currentLanguage = localStorage.getItem("language");
  if (currentLanguage === null) {
    localStorage.setItem("language", "zh");
    return "zh";
  } else {
    return currentLanguage;
  }
};

getTheDefaultLanguage();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getTheDefaultLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
