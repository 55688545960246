import React, { useState } from "react";
import styled from "styled-components";

import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

export default function ForgetPasswordEmail() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const sendCodetoEmail = () => {
    navigate("/check-email");
  };

  return (
    <StyledWrapper>
      <p className="back-arrow" onClick={() => navigate(-1)}>
        {"<"}
      </p>
      <img className="my-5" src="./images/login-icons/mail.png" alt="" />
      <h2>Forget your password?</h2>
      <p className="my-4">
        Enter your registered email below to receive <br /> password reset
        instruction
      </p>

      <div className="d-flex flex-row justify-content-center align-items-center py-4">
        <p className="mx-4" style={{ fontWeight: 700 }}>
          Email
        </p>
        <a href="/forget-password-phone">
          <p className="mx-4">Phone Number</p>
        </a>
      </div>

      <p className="label">Email</p>
      <input
        className="my-input w-100"
        value={email}
        placeholder="xxxxxx@richlinkwallet.com"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        type="text"
      />
      <div className="text-center mt-5" onClick={sendCodetoEmail}>
        <Button text="Send" />
      </div>
      <p className="back-to-login" onClick={() => navigate("/login")}>
        Back to Login
      </p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  padding: 40px;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  > .label {
    text-align: left;
    padding: 10px 0px;
  }
  .back-arrow {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 40px;
  }
  .back-to-login {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 50px);
  }
  a {
    text-decoration: none;
  }
`;
